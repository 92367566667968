<template>
    <v-container fluid>
        <v-form @submit.prevent="saveClient">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ heading }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="2" class="mt-2 px-sm-6 text-center">
                                <make-avatar v-model="photo" :aspect-ratio="1 / 1"/>
                            </v-col>
                            <v-col cols="12" sm="9" md="10">
                                <v-row>
                                    <v-col class="py-0" cols="6">
                                        <ValidationProvider ref="login" rules="required|min:2|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="login" type="text" :error="!valid"
                                                          :error-messages="errors" :disabled="loading"
                                                          prepend-icon="mdi-card-account-details-outline"
                                                          :label="$t('login')" color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="6">
                                        <ValidationProvider ref="language" rules="required" v-slot="{ errors, valid }">
                                            <v-select v-model="language" :items="languages" :error="!valid"
                                                      :error-messages="errors" :disabled="loading" item-value="id"
                                                      item-text="name" prepend-icon="mdi-translate" color="primary"
                                                      :label="$t('client_language_of_your_personal_account_and_mailing')"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="7">
                                        <ValidationProvider ref="name" rules="required|min:2|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="name" type="text" :error="!valid"
                                                          :error-messages="errors" :disabled="loading"
                                                          prepend-icon="mdi-account" :label="$t('client_name')"
                                                          color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="5">
                                        <ValidationProvider ref="manager" rules="min:1" v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="manager" :items="managerItems" :error="!valid"
                                                            :error-messages="errors" :search-input.sync="managerSearching"
                                                            item-text="name" item-value="id" prepend-icon="mdi-account-tie"
                                                            :loading="loadingManagers" :disabled="loading || can('manager')"
                                                            @click="clearManagers"
                                                            :no-data-text="managerSearching ? $t('nothing_found_by', {search: managerSearching}) : $t('nothing_found_manager_name')"
                                                            :label="$t('client_personal_manager')" @click:clear="managerItems = []"
                                                            color="primary" return-object clearable autocomplete="off">
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="phone" rules="phone" v-slot="{ errors, valid }">
                                            <v-text-field v-model="phoneRaw" type="tel" v-mask="phoneMask"
                                                          :error-messages="errors" :error="!valid" :disabled="loading"
                                                          prepend-icon="mdi-phone" :label="$t('phone')" color="primary"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="email" rules="email" v-slot="{ errors, valid }">
                                            <v-text-field v-model="email" type="email" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-email" label="Email"
                                                          color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="country" rules="min:1" v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="country" :items="countryItems" :error="!valid"
                                                            :error-messages="errors"
                                                            :search-input.sync="countrySearching"
                                                            item-text="name" item-value="id" prepend-icon="mdi-web"
                                                            :loading="loadingCountries" :disabled="loading"
                                                            @click="clearCountries"
                                                            :no-data-text="countrySearching ? $t('nothing_found_by', {search: countrySearching}) : $t('nothing_found_country_name')"
                                                            :label="$t('country')" @click:clear="countryItems = []"
                                                            color="primary" return-object clearable autocomplete="off">
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="city" rules="min:1" v-slot="{ errors, valid }">
                                            <v-autocomplete v-model="city" :items="cityItems" :error="!valid"
                                                            :error-messages="errors" :search-input.sync="citySearching"
                                                            item-text="name" item-value="id" prepend-icon="mdi-city"
                                                            :loading="loadingCities" :disabled="loading || !country"
                                                            @click="clearCities"
                                                            :no-data-text="citySearching ? $t('nothing_found_by', { search: citySearching }) : $t('nothing_found_city_name')"
                                                            :label="$t('city')" @click:clear="cityItems = []"
                                                            color="primary" return-object clearable autocomplete="off">
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="companies" rules="min:1" v-slot="{ errors, valid }">
                                            <v-combobox v-model="companies" :items="companyItems"
                                                        :error-messages="errors" :search-input.sync="companySearching"
                                                        item-text="name" item-value="id" :loading="loadingCompanies"
                                                        prepend-icon="mdi-home-city" :disabled="loading"
                                                        :label="$t('client_companies')"
                                                        @click:clear="companyItems = []" @click="clearCompanies"
                                                        color="primary" multiple return-object hide-selected
                                                        clearable autocomplete="off">
                                                <template v-slot:item="{attrs, item, parent, selected}">
                                                    <span class="search-list">
                                                        <span v-html="item.name"></span>
                                                        (<span v-text="item.city"></span>
                                                        <span v-text="item.address"></span>
                                                        <span v-text="item.phone ? '+' + item.phone : ''"></span>)
                                                    </span>
                                                </template>
                                                <template v-slot:selection="{attrs, item, parent, selected}">
                                                    <v-chip v-if="item === Object(item)"
                                                            v-bind="attrs" :input-value="selected"
                                                            :to="{name: 'company.edit', params: {id: item.id}}"
                                                            @click:close="parent.selectItem(item)"
                                                            :color="item.active ? 'green lighten-5' : 'red lighten-5'"
                                                            :title="item.active ? $t('active') : $t('no_active')"
                                                            link close>
                                                        <span v-html="item.name"></span>
                                                    </v-chip>
                                                </template>
                                                <template v-slot:no-data>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{companySearching ? $t("nothing_found_by", {search: companySearching}) : $t("nothing_found_company_name") }}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-combobox>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="can(['administrator', 'manager'])" cols="12">
                                <ValidationProvider ref="notes" rules="min:3|max:65535" v-slot="{ errors, valid }">
                                    <v-textarea v-model="notes" type="text" :error-messages="errors"
                                                :disabled="loading" :label="$t('notes')" rows="5"
                                                color="primary" auto-grow clearable outlined>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-3" cols="12">
                                <h3 class="headline">
                                    {{ $t("client_access_password") }}
                                </h3>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="password" :rules="form_required + 'min:8'" vid="password"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="password" :error="!valid && create"
                                                  :error-messages="errors" :disabled="loading"
                                                  :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                                  :type="passwordHidden ? 'password' : 'text'"
                                                  @click:append="() => (passwordHidden = !passwordHidden)"
                                                  prepend-icon="mdi-lock" :label="$t('enter_password')"
                                                  color="primary" clearable required></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="confirmation_password"
                                                    rules="required_if:password|confirmed:password"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="password_confirmation" :error="!valid"
                                                  :error-messages="errors" :disabled="loading"
                                                  :append-icon="passwordConfirmHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                                  :type="passwordHidden ? 'password' : 'text'"
                                                  @click:append="() => (passwordConfirmHidden = !passwordConfirmHidden)"
                                                  prepend-icon="mdi-lock" :label="$t('repeat_password')"
                                                  color="primary" clearable required>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <v-switch v-model="mailing" :disabled="!email || loading" class="mt-0"
                                          :label="$t('client_receive_mailings_about_the_location_of_wagons')"
                                          color="primary" hide-details>
                                </v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0 d-flex justify-start" cols="12" sm="6">
                                <v-switch v-model="deleted" :disabled="loading"
                                          :label="deleted ? $t('client_deleted') : $t('client_remove')"
                                          @change="deleted ? (active = false) : active"
                                          color="red"></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="active" :disabled="loading || deleted" :label="$t('client_active')"
                                          color="primary"></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading" color="primary">
                            {{ $t("save") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>
import debounce from "lodash/debounce"
import {ValidationObserver, ValidationProvider} from "vee-validate"
import {mask} from "vue-the-mask"
import {mapGetters} from "vuex"
import MakeAvatar from "../components/MakeAvatar.vue"

export default {
    name: "ClientForm",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        MakeAvatar,
    },
    inject: ["forceRerender"],
    data() {
        return {
            heading: null,
            progress: 0,
            create: false,
            phoneRaw: null,
            name: null,
            login: null,
            email: null,
            notes: null,
            photo: null,
            language: 'en',
            languages: [],
            mailing: false,
            active: false,
            deleted: false,
            password: null,
            passwordHidden: true,
            password_confirmation: null,
            passwordConfirmHidden: true,
            loading: false,
            loadingCities: false,
            loadingManagers: false,
            loadingCountries: false,
            loadingCompanies: false,
            manager: null,
            managerItems: [],
            managerSearching: null,
            country: null,
            countryItems: [],
            countrySearching: null,
            city: null,
            cityItems: [],
            citySearching: null,
            companies: null,
            companyItems: [],
            companySearching: null,
        }
    },
    computed: {
        ...mapGetters(["lang", "phoneMask"]),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
        form_required: function () {
            if (this.create) {
                return "required|"
            }
            return ""
        },
    },
    watch: {
        managerSearching: debounce(function (val) {
            if (val && !this.manager) {
                this.getManagers(val)
            } else {
                this.clearManagers()
            }
        }, 500),
        countrySearching: debounce(function (val) {
            if (val && !this.country) {
                this.getCountries(val)
            }
        }, 500),
        citySearching: debounce(function (val) {
            if (val && this.country && !this.city) {
                this.getCities(val)
            }
        }, 500),
        companySearching: debounce(function (val) {
            if (val) {
                this.getCompanies(val)
            }
        }, 500),
        password(val) {
            if (val && val !== this.password_confirmation) {
                this.$refs.confirmation_password.setErrors([
                    this.$t("validations.rules.confirmed", {
                        _field_: this.$t(
                            "validations.fields.confirmation_password"
                        ),
                    }),
                ])
            }
        },
        password_confirmation(val) {
            if (val && val !== this.password) {
                this.$refs.password.setErrors([
                    this.$t("validations.rules.confirmed", {
                        _field_: this.$t("validations.fields.password"),
                    }),
                ])
            } else {
                this.$refs.password.reset()
            }
        },
        email(val) {
            if (!val) {
                this.mailing = false
            }
        },
    },
    mounted() {
        this.getLanguages()
        this.checkCreate()
    },
    methods: {
        clearManagers() {
            if (!this.manager) {
                this.managerItems = []
            }
        },
        clearCountries() {
            if (!this.country) {
                this.countryItems = []
                this.city = null
                this.cityItems = []
            }
        },
        clearCities() {
            if (!this.city) {
                this.cityItems = []
            }
        },

        clearCompanies() {
            if (!this.companies) {
                this.companyItems = []
            }
        },
        checkCreate() {
            if (this.$route.name === "client.create") {
                this.create = true
                this.heading = this.$t("client_creation")
            } else if (this.$route.name === "profile" && this.$auth.check()) {
                this.heading = this.$t("client_editing")
                if (this.$auth.user().id) {
                    this.getClient(this.$auth.user().id)
                }
            } else {
                this.heading = this.$t("client_editing")
                if (this.$route.params.id) {
                    this.getClient(this.$route.params.id)
                }
            }
        },
        async getLanguages() {
            var _this = this
            this.progress = 0
            this.loading = true
            await this.$http
                .get("admin/language", {
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round(
                                (e.loaded / e.total) * 100
                            )
                        }
                    },
                })
                .then((res) => {
                    this.languages = res.body?.data
                })
                .catch((err) => {
                    this.$toastr.error(this.$t("failed_to_get_list_languages"))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0],
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally((end) => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async getCompanies(str) {
            if (str) {
                this.loadingCompanies = true
                let params = {}
                params.filter = "search"
                if (str !== "undefined") {
                    params.company = str
                }
                await this.$http
                    .get("admin/company", {
                        params: params,
                    })
                    .then((res) => {
                        this.companyItems = res.body.data
                    })
                    .catch((err) => {
                        this.companyItems = []
                        this.$toastr.error(
                            this.$t("failed_to_get_list_companies")
                        )
                    })
                    .finally((end) => {
                        this.loadingCompanies = false
                    })
            }
        },
        async getCities(str) {
            if (str) {
                this.loadingCities = true
                let params = {}
                params.filter = "search"
                if (str !== "undefined") {
                    params.city = str
                }
                if (this.country) {
                    if (this.country.id) {
                        params.country = this.country.id
                    } else {
                        params.country = this.country
                    }
                }
                await this.$http
                    .get("admin/city", {
                        params: params,
                    })
                    .then((res) => {
                        this.cityItems = res.body.data
                    })
                    .catch((err) => {
                        this.cityItems = []
                        this.$toastr.error(
                            this.$t("failed_to_get_list_cities")
                        )
                    })
                    .finally((end) => {
                        this.loadingCities = false
                    })
            }
        },
        async getCountries(str) {
            if (str) {
                this.loadingCountries = true
                let params = {}
                params.filter = "search"
                if (str !== "undefined") {
                    params.country = str
                }
                await this.$http
                    .get("admin/country", {
                        params: params,
                    })
                    .then((res) => {
                        this.countryItems = res.body.data
                    })
                    .catch((err) => {
                        this.countryItems = []
                        this.$toastr.error(
                            this.$t("failed_to_get_list_countries")
                        )
                    })
                    .finally((end) => {
                        this.loadingCountries = false
                    })
            }
        },
        async getManagers(str) {
            if (str) {
                this.loadingManagers = true
                let params = {}
                params.filter = "search"
                if (str !== "undefined") {
                    params.country = str
                }
                await this.$http
                    .get("admin/manager", {
                        params: params,
                    })
                    .then((res) => {
                        this.managerItems = res.body.data
                    })
                    .catch((err) => {
                        this.managerItems = []
                        //this.$toastr.error(this.$t("failed_to_get_list_managers"))
                    })
                    .finally((end) => {
                        this.loadingManagers = false
                    })
            }
        },
        async getClient(id) {
            var _this = this
            this.progress = 0
            this.loading = true
            this.id = id
            await this.$http
                .get(`admin/client/${id}`, {
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round(
                                (e.loaded / e.total) * 100
                            )
                        }
                    },
                })
                .then((res) => {
                    this.login = res.body.data.login
                    this.name = res.body.data.name
                    this.phoneRaw = res.body.data.phone
                    this.email = res.body.data.email
                    this.photo = res.body.data.photo
                    this.mailing = res.body.data.mailing
                    this.active = res.body.data.active
                    this.deleted = res.body.data.deleted
                    this.notes = res.body.data.notes
                    this.companies = res.body.data.companies

                    let manager = res.body.data.manager

                    if (this.can('manager')) {
                        manager = this.$auth.user()
                    }

                    if (manager && manager.id) {
                        this.manager = manager.id
                        this.managerItems = [manager]
                    } else {
                        this.managerItems = []
                    }

                    let country = res.body.data.country
                    if (country && country.id) {
                        this.country = country.id
                        this.countryItems = [country]
                    } else {
                        this.countryItems = []
                    }

                    let city = res.body.data.city
                    if (city && city.id) {
                        this.city = city.id
                        this.cityItems = [city]
                    } else {
                        this.cityItems = []
                    }
                })
                .catch((err) => {
                    this.$toastr.error(this.$t("failed_to_get_client"))
                })
                .finally((end) => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveClient() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.name) {
                formData.append("name", this.name)
            }
            if (this.phone) {
                formData.append("phone", this.phone)
            }
            if (this.email) {
                formData.append("email", this.email)
            }
            if (this.login) {
                formData.append("login", this.login)
            }
            if (this.password) {
                formData.append("password", this.password)
            }
            if (this.notes) {
                formData.append("notes", this.notes)
            }
            if (this.active) {
                formData.append("active", 1)
            }
            if (this.deleted) {
                formData.append("deleted", 1)
            }
            if (this.mailing) {
                formData.append("mailing", 1)
            }
            if (this.language) {
                formData.append("language", this.language)
            }
            if (this.manager) {
                if (this.manager.id) {
                    formData.append("manager", this.manager.id)
                } else {
                    formData.append("manager", this.manager)
                }
            }
            if (this.country) {
                if (this.country.id) {
                    formData.append("country", this.country.id)
                } else {
                    formData.append("country", this.country)
                }
            }
            if (this.city) {
                if (this.city.id) {
                    formData.append("city", this.city.id)
                } else {
                    formData.append("city", this.city)
                }
            }
            if (this.companies && this.companies.length > 0) {
                for (let i in this.companies) {
                    if (this.companies[i].id !== undefined && this.companies[i].id > 0) {
                        formData.append(`companies[${i}]`, this.companies[i].id)
                    }
                }
            }
            if (this.photo) {
                if (this.photo.length > 250) {
                    var mimeType = this.getMimeType(this.photo)
                    var blob = this.dataURL64toBlob(this.photo)
                    if (mimeType && blob) {
                        formData.append("photo", blob, mimeType)
                    }
                } else {
                    formData.append("photo", this.photo)
                }
            }
            if (this.$route.params.id) {
                // Save
                await this.$http
                    .put(`admin/client/${this.$route.params.id}`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round(
                                    (e.loaded / e.total) * 100
                                )
                            }
                        },
                    })
                    .then((res) => {
                        this.$toastr.success(
                            this.$t("client_has_been_updated")
                        )
                        //this.forceRerender()
                    })
                    .catch((err) => {
                        this.$toastr.error(
                            this.$t("client_has_not_been_updated")
                        )
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (
                                    hasOwnProperty.call(err.body.errors, prop)
                                ) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0],
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally((end) => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post("admin/client", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round(
                                    (e.loaded / e.total) * 100
                                )
                            }
                        },
                    })
                    .then((res) => {
                        this.$toastr.success(this.$t("client_has_been_added"))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.$router.push({
                                name: "client.edit",
                                params: {
                                    id: res.body.data.id,
                                },
                            })
                        } else {
                            this.$router.push({name: "client"})
                        }
                    })
                    .catch((err) => {
                        this.$toastr.error(
                            this.$t("client_has_not_been_added")
                        )
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (
                                    hasOwnProperty.call(err.body.errors, prop)
                                ) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0],
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally((end) => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
